.InstructionButtons {
  display: flex;
  /* gap: 2.4rem; */
}
.InstructionButtons > * {
  margin: 0 1.2rem;
}

.InstructionButtons button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 0.5rem; */
  font-size: 2.4rem;
}
.InstructionButtons button > * {
  margin: 0 0.25rem;
}

.InstructionButtons-start {
  background-color: var(--gensmak-neon);
}

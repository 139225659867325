.GameMenu-icon {
  width: 1.6rem;
  height: 1.6rem;
  border: none;
  background: transparent;
}
.GameMenu-menu-icon {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 1.6rem;
}
.GameMenu-menu {
  position: fixed;
  /* inset: 0; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: #fff;
  z-index: 20;
}
.GameMenu-items {
  border-right: 2px solid #000;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  /* gap: 1.6rem; */
}
.GameMenu-item {
  border-radius: 1.2rem;
  transition: background-color 0.2s ease;
  border: none;
  padding: 1.2rem 1.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  font-weight: bold;
  background: transparent;
  /* gap: 1.2rem; */
  cursor: pointer;
  margin-top: 1.6rem;
}
.GameMenu-item > span {
  margin: 0 1.2rem;
}
.GameMenu-close {
  padding: 1.2rem;
  margin-right: auto;
}
.GameMenu-item.unfocused:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.GameMenu-item:focus {
  /* border: none; */
}
.GameMenu-menu > * {
  flex: 1;
  overflow: hidden;
}
.GameMenu-item > span {
  margin-right: auto;
}
.GameMenu-focus {
  position: absolute;
  background: var(--gensmak-neon);
  top: 0;
  left: 0;
  width: 10rem;
  height: 10rem;
  z-index: -10;
  border-radius: 1.2rem;
  transition: all 0.2s ease !important;
  box-sizing: border-box;
}
.GameMenu-content > * {
  width: 100%;
  overflow: hidden;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}
.GameMenu-join-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* gap: 2.4rem; */
}
.GameMenu-join-panel > * {
  margin: 1.2rem 0;
}
.GameMenu-join-qrcode {
  padding: 1.2rem;
  width: 18rem;
  height: 18rem;
  background: var(--gensmak-neon);
  border: 2px solid #000;
  border-radius: 1.2rem;
}
.GameMenu-join-link {
  background: var(--gensmak-neon);
  padding: 0.5rem;
  font-size: 3.2rem;
  font-weight: bold;
}
.GameMenu-rules-panel,
.GameMenu-sound-panel {
  padding: 3.6rem;
  font-size: 1.8rem;
  line-height: 1.4;
}
.GameMenu-rules-panel > * {
  margin-bottom: 2.4rem;
}
.GameMenu-rules-panel > h3 {
  background: var(--gensmak-neon);
  padding: 0.5rem;
}
.GameMenu-upgrade-panel-banner {
  width: 100%;
  max-height: 40%;
  object-fit: cover;
  object-position: top;
}
.GameMenu-logo {
  font-style: italic;
  font-size: 3.2rem;
}
.GameMenu-upgrade-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 3.6rem;
  /* gap: 2.4rem; */
  justify-content: space-between;
}
.GameMenu-upgrade-content > * {
  margin: 1.2rem 0;
}
.GameMenu-upgrade-content > h2 {
  font-size: 3.2rem;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
}
.GameMenu-upgrade-content > p {
  font-size: 1.6rem;
}
.GameMenu-packs {
  display: flex;
  overflow-x: scroll;
  overflow-y: visible;
  /* gap: 1.2rem; */
  padding: 6rem 3.6rem;
}
.GameMenu-packs > * {
  margin: 0 0.6rem;
}
.GameMenu-pack {
  width: 12.8rem;
  height: 12.8rem;
  position: relative;
  flex: 0 0 12.8rem;
  padding: 0;
  border: none;
}
.GameMenu-pack:not(.disabled)::before {
  content: '';
  position: absolute;
  /* inset: 0; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0.8rem solid #e5e5e5;
  border-radius: 0.6rem;
  background: #e5e5e5;
  z-index: -1;
  margin: -0.8rem;
}
.GameMenu-pack.disabled > img,
.GameMenu-pack.disabled > h2 {
  opacity: 0.5;
}
.GameMenu-pack.disabled::after {
  content: 'Coming soon';
  text-transform: uppercase;
  font-size: 1.2rem;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: -2.4rem;
  left: 0;
  font-weight: bold;
  font-style: italic;
}
.GameMenu-pack > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.GameMenu-pack > h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.2rem;
  box-sizing: border-box;
  font-style: italic;
  font-size: 2.4rem;
  font-weight: bold;
  text-transform: uppercase;
}
.GameMenu-upgrade-panel {
  display: flex;
  flex-direction: column;
}
.GameMenu-upgrade-price {
  margin-top: auto;
}
.GameMenu-buy-now {
  border-radius: 1.2rem;
}
.GameMenu-links-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* gap: 0.6rem; */
  font-size: 1.2rem;
  font-weight: bold;
}
.GameMenu-links-panel > * {
  margin: 0.3rem 0;
}
.GameMenu-links-panel > h2 {
  margin-bottom: 2.4rem;
  font-size: 3.2rem;
}
.GameMenu-privacy-panel > h2 {
  font-size: 2rem;
}
.GameMenu-new-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* gap: 2.4rem; */
  padding: 3.6rem;
  font-size: 2rem;
}
.GameMenu-new-panel > * {
  margin: 1.2rem 0;
}
.GameMenu-exit-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* gap: 2.4rem; */
  padding: 3.6rem;
  font-size: 2rem;
}
.GameMenu-exit-panel > * {
  margin: 1.2rem 0;
}
.GameMenu-smile-sticker {
  width: 6rem;
  height: 6rem;
  position: absolute;
  top: 6rem;
  right: 4rem;
}
.GameMenu-sound-panel {
  display: flex;
  flex-direction: column;
  /* gap: 3.6rem; */
}
.GameMenu-sound-panel > * {
  margin: 1.2rem 0;
}
.GameMenu-sound-panel > div {
  display: flex;
  flex-direction: column;
  /* gap: 1.2rem; */
}
.GameMenu-sound-panel > div > * {
  margin: 0.6rem 0;
}
.GameMenu-sound-button {
  display: flex;
  position: relative;
  padding: 1.2rem 4rem;
  width: 100%;
  box-sizing: border-box;
  border-radius: 1.2rem;
  cursor: pointer;
  background: white;
  border: none;
  font-size: 1.8rem;
}
.GameMenu-sound-button.on {
  background: #e5e5e5;
}
.GameMenu-sound-button.on::before {
  content: '';
  background-image: url('../images/check.svg');
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 50%;
  left: 1.3rem;
  /* translate: 0 -50%; */
  transform: translate(0, -50%);
}

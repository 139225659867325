.card {
  height: fit-content;
  text-align: left;
}

.card > * {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: contain;
  width: 100%;
  max-height: 100%;
}
.card > .card-background {
  position: relative;
}
.card-shadow {
  /* translate: 8px 8px; */
  transform: translate(8px, 8px);
}

.ScoreCard {
  font-size: clamp(1.5rem, 3vmax, 2.4rem);
  padding: 1em;
  box-sizing: border-box;
  white-space: nowrap;
  border-radius: 1em;
  --border-color: transparent;
  --border-width: 10px;
  box-shadow: 0 0 0 var(--border-width) var(--border-color);
  border: 2px solid #000;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* gap: 1em; */
  background-color: #fff;
  justify-content: space-between;
  transition: box-shadow 0.3s ease;
}
.ScoreCard.active {
  --border-color: var(--gensmak-neon);
}
.ScoreCard-team-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 1em;
}
.ScoreCard-team {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.3em 0 0;
  padding: 0;
  /* gap: 0.3em; */
}
.ScoreCard-team > li {
  display: inline-block;
  padding: 0.125em 0.3em;
  margin: 0 0.15em;
}
.ScoreCard-gen-checks {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* gap: 0.666em; */
}
.ScoreCard-check {
  width: 2em;
  height: 2em;
  margin: 0 0.3em;
}
.ScoreCard-score {
  display: flex;
  /* gap: 1em; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.ScoreCard-scores-team-points {
  height: 2em;
  border: 2px solid black;
  border-radius: 9999px;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1;
  min-width: fit-content;
  margin-left: 1em;
  justify-content: flex-start;
}
.ScoreCard-scores-team-points.invert {
  justify-content: flex-end;
}
.ScoreCard-scores-team-points-fill {
  position: absolute;
  left: 0;
  height: 102%;
  border-radius: 9999px;
  background-color: black;
  transition: width 0.2s ease;
}
.ScoreCard-scores-team-points-text {
  margin: 0.4em;
  color: white;
  background: black;
  border-radius: 999px;
  padding: 0.2rem 0.4rem;
  font-weight: bold;
  position: relative;
}
.ScoreCard-scores-team-points.invert .ScoreCard-scores-team-points-text {
  color: black;
  background: white;
}
html[data-device='host'] .ScoreCard {
  font-size: 1.2rem;
  --border-width: 1em;
}
html[data-device='host'] .ScoreCard-team > li {
  display: inline-block;
  font-size: 1em;
  padding: 0.125em 0.3em;
}
html[data-device='host'] .ScoreCard h2 {
  font-size: 1.3em;
}

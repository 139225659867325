@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/HelveticaNeue-01.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/HelveticaNeue-Bold-02.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/HelveticaNeue-Italic-03.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/HelveticaNeue-BoldItalic-04.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

/* This is to start setting up for moving all units to rem: */
html {
  font-size: 62.5%;
}

/* We set the root font size smaller for small phones */
@media (max-width: 400px) {
  html {
    font-size: 50%;
  }
}

/* for TVs (and other large screens) 
TODO: use JS to only set this if on TV devices?
*/
@media (min-width: 1200px) {
  html {
    /* keeping for reference */
    /* font-size: 125%; */
    font-size: min(1.85vh, 1.04vw); /* like vmin but for 16:9 aspect ratio */
  }
}
/* This is set in the root host element */
html[data-device='host'] {
  font-size: min(1.85vh, 1.04vw);
}

body {
  font-size: 2.4rem;
  line-height: 1.2;
}

img {
  pointer-events: none;
  user-select: none;
}

h1,
h2,
h3 {
  font-weight: bold;
  margin: 0;
}

p {
  margin: 0;
}

h3 {
  font-size: 2.4rem;
}

h2 {
  font-size: 3.2rem;
}

h1 {
  font-size: 4.8rem;
}

body,
html,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: left;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  color: #000000;
  position: relative;
  background-color: #ffffff;
}

.screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: fixed;
  /* inset: 0; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.screen > main {
  flex: 1;
  min-height: 0;
  position: relative;
}

input,
textarea,
button {
  font-family: inherit;
}

button {
  user-select: none;
  color: #000;
}

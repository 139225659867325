.HostPassword {
  background: #eee;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}
.HostPassword h1 {
  font-size: 2em;
  font-style: italic;
  line-height: 1.2;
  position: absolute;
  top: 2em;
  left: 4em;
}
.HostPassword-input {
  background: #fff;
  border-radius: 2em;
  padding: 2em;
  display: flex;
  /* gap: 1.5em; */
  flex-direction: column;
  align-items: stretch;
  width: 24em;
  --accent-color: #f4c251;
}
.HostPassword > * {
  margin: 0.75em 0;
}
.HostPassword input {
  border-radius: 0.5em;
  font-size: 1.5em;
  padding: 0.5em;
  outline: #ddd auto 1px;
}
.HostPassword input:focus {
  outline: var(--accent-color) auto 1px;
}
.HostPassword .normal-button {
  border-radius: 999px;
  font-size: 1.25em;
  padding: 0.75em;
  border: none;
  background: var(--accent-color);
  transition: all 0.2s ease;
}
.HostPassword p:last-of-type:before {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: #aaa;
  margin: 1em 0 3em;
}
.HostPassword .keymailer-button {
  border-radius: 0.1em;
  height: 4em;
  overflow: hidden;
  border: none;
  background: transparent;
  transition: all 0.2s ease;
  margin: 0 auto;
  width: 100%;
}
.HostPassword .keymailer-button > img {
  height: 100%;
}
.HostPassword button:hover {
  scale: 1.02;
  cursor: pointer;
}
.HostPassword p {
  font-size: 1.5em;
  margin-bottom: 1em;
  text-align: center;
}

@media (max-width: 700px) {
  .HostPassword {
    font-size: 3rem;
  }
  .HostPassword h1 {
    font-size: 1em;
    font-style: italic;
    line-height: 1.2;
  }
}

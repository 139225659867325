.SlideUpModal-background {
  position: fixed;
  /* inset: 0; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}
.SlideUpModal {
  box-sizing: border-box;
  bottom: 0;
  width: 100%;
  z-index: 101;
  padding: 24px;
  background: #fff;
  border-radius: 24px 24px 0 0;
  height: fit-content;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SlideUpModal * {
  box-sizing: border-box;
  overflow: hidden;
}
.SlideUpModal-open {
  background: var(--gensmak-neon);
}
.SlideUpModal-fully-open {
  position: absolute;
}
.SlideUpModal-warning {
  font-size: 3.6rem;
  max-width: 65ch;
  margin: 0;
  white-space: normal;
}
.SlideUpModal-cancel,
.SlideUpModal-warning,
.SlideUpModal-background,
.SlideUpModal-button {
  transition: all 0.3s ease;
}

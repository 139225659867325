.HostGameOver {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--gensmak-neon);
}

.HostGameOver > h1 {
  font-size: 8rem;
  font-style: italic;
  text-transform: uppercase;
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* gap: 1.2rem; */
}
.HostGameOver > h1 > * {
  margin: 0.6rem 0;
}
.HostGameOver-survey > a {
  color: #000000;
}
.HostGameOver-team {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  /* gap: 0.8rem; */
}
.HostGameOver-team > * {
  margin: 0.4rem;
}
.HostGameOver-team > li {
  display: inline-block;
  font-size: 1.6rem !important;
}

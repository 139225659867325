.PlayerScreenPlayGeneration-title {
  text-align: center;
}

.PlayerScreenPlayGeneration {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2.4rem 2.4rem 4.8rem;
  box-sizing: border-box;
}

.PlayerScreenPlayGeneration-cards {
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* gap: 1.4rem; */
  /* TODO: still need a better layout option for this */
  min-height: 0;
}

.PlayerScreenPlayGeneration-card {
  position: relative;
  width: 100%;
  flex: 0 1 30%;
}

.PlayerScreenPlayGeneration-card-confirm {
  position: relative;
  width: 70%;
  max-height: 50%;
}
.PlayerScreenPlayGeneration-confirm {
  margin: 2.4rem 0;
  background-color: var(--gensmak-neon);
}

@media (min-width: 700px) {
  .PlayerScreenPlayGeneration-card {
    flex: 0 1 18%;
  }
}

.PlayerScreenPlayGeneration-bonus {
  font-weight: bold;
  border: 1px solid #000000;
  border-radius: 8px;
  color: #000;
  background-color: #fff;
  font-size: 1.2rem;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.PlayerScreenPlayGeneration-bonus-label {
  padding-right: 10px;
  font-size: 2.4rem;
}
.PlayerScreenPlayGeneration.score {
  justify-content: flex-start;
  align-items: stretch;
  /* gap: 3.2rem; */
  padding: 2.4rem;
}
.PlayerScreenPlayGeneration-score-card {
  flex: 0 1 auto;
  min-height: 0;
}
.PlayerScreenPlayGeneration-score-back {
  margin: auto auto 0;
}
.PlayerScreenPlayGeneration-show-score {
  flex: 0 0 auto;
  min-height: 0;
}

.StartScreen-start-button {
  width: 15rem;
  font-size: 3.2rem;
  background-color: var(--gensmak-neon);
  text-transform: uppercase;
  position: absolute;
  right: 6.4rem;
  bottom: 6.4rem;
}
.StartScreen-instructions-button {
  width: fit-content;
  font-size: 3.2rem;
  background-color: white;
  background-image: url("../../images/setup_instructions_button.png");
  background-repeat: no-repeat;
  background-size: 15%;
  background-position: left 5% top 40%;
  padding-left: 15%;
  text-transform: uppercase;
  position: absolute;
  left: 6.4rem;
  bottom: 6.4rem;
  transform: translate(-50% -50%);
}
.StartScreen-background {
  position: absolute;
  z-index: -1;
  /* inset: 0; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.StartScreen-sticker {
  position: absolute;
  transform-origin: center;
}
.StartScreen-packs {
  display: flex;
  overflow-x: hidden;
  overflow-y: visible;
  /* gap: 1.2rem; */
  padding: 6rem 3.6rem;
  width: calc(100% - 7.2rem);
  align-items: center;
  position: absolute;
  bottom: 2rem;
}
.StartScreen-packs > * {
  margin: 0 0.6rem;
}

.StartScreen-card {
  margin: 2.4rem;
  border-radius: 0.6rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  height: 17rem;
  width: 37.4rem;
}

.StartScreen-card > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.StartScreen-card h3 {
  font-size: 1.5rem;
  color: white;
  text-transform: uppercase;
  position: absolute;
  font-style: italic;
  top: 0;
  left: 0;
  margin: 1.6rem;
}

.StartScreen-card h3.discount {
  font-size: 1.2rem;
  color: black;
  background: var(--gensmak-neon);
  text-transform: uppercase;
  width: 7rem;
  height: 7rem;
  border-radius: 100%;
  left: auto;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.StartScreen-card .qrcode {
  width: 7rem;
  height: 7rem;
  bottom: 0;
  left: 0;
  position: absolute;
  margin: 1.6rem;
  border: 0.3rem solid white;
}

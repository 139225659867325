.PlayerScreenPlayWait-center {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.PlayerScreenPlayWait-center > * {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}

.PlayerScreenPlayWait-hang {
  rotate: -10deg;
  color: white;
  text-align: center;
  vertical-align: middle;
}

.PlayerScreenPlayWait-star-img {
  height: 45vh;
  width: 45vh;
}

.PlayerScreenPlayWait-smile-img {
  rotate: 20deg;
  height: 20vh;
  width: 16vh;
  translate: calc(-50% + 15vh) calc(-50% - 15vh);
}

.PlayerScreenPlayWait-last {
  position: absolute;
  bottom: 2.4rem;
  right: 2.4rem;
  border-radius: var(--border-radius);
  text-align: center;
  vertical-align: middle;
  color: #000;
  background-color: #000;
  padding: 1.6rem;
}

.PlayerScreenPlayWait-last-correct {
  background-color: var(--correct-color);
}

.PlayerScreenPlayWait-last-incorrect {
  background-color: var(--wrong-color);
}

.PlayerScreenPlayWait-last > * {
  text-align: center;
}

.PlayerScreenPlayWait-last-text {
  padding-bottom: 1.6rem;
}

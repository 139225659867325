.PlayerScreenPlayCaptain {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  box-sizing: border-box;
  position: relative;
  font-size: 2.4rem;
  font-weight: bold;
  overflow: hidden;
}

.PlayerScreenPlayCaptain-answer-wrapper {
  background: #000;
  border-radius: 24px;
  overflow: hidden;
  width: 100%;
  min-height: 30%;
  position: relative;
  margin: 0 auto 1rem;
}

.PlayerScreenPlayCaptain-answer-wrapper > * {
  position: absolute;
  /* inset: 0; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.PlayerScreenPlayCaptain-answer,
.PlayerScreenPlayCaptain-answer-hidden {
  color: #fff;
}
.PlayerScreenPlayCaptain-answer-hidden {
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transition: opacity 0.3s ease;
  margin: auto;
}
.PlayerScreenPlayCaptain-answer-hidden > img {
  padding: 12px;
}

.PlayerScreenPlayCaptain-answer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 2.4rem;
  font-size: 3.2rem;
}
.PlayerScreenPlayCaptain-answer > p {
  margin: 0;
  transition: all 0.3s ease;
  position: absolute;
  padding: 1rem 2.4rem;
}

.PlayerScreenPlayCaptain-peek {
  font-size: 2.4rem;
  font-weight: bold;
  padding: 16px;
  border-radius: 9999px;
  background: #fff;
  width: 100%;
  border: none;
}

.PlayerScreenPlayCaptain-peek.active,
.PlayerScreenPlayCaptain-peek:active {
  background: #000;
  color: #fff;
  opacity: 0;
}
.PlayerScreenPlayCaptain h2 {
  font-weight: bold;
  font-size: 2.4rem;
  text-align: center;
  width: 100%;
  margin: 0 0 1em 0;
}
.PlayerScreenPlayCaptain .PlayerScreenPlayCaptain-prompt {
  margin: auto 0 1em 0;
}
.PlayerScreenPlayCaptain-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 1em 0;
  flex: 0 1 auto;
  min-height: 8rem;
}
.PlayerScreenPlayCaptain-answer-button {
  --size: min(20rem, 36vw);
  max-width: var(--size);
  height: var(--size);
  max-height: 100%;
  font-size: 3.2rem;
  font-weight: bold;
  border: 3px solid black;
  border-radius: 9999px;
  transition: all 0.2s ease;
  aspect-ratio: 1;
  margin: 0;
  padding: 0;
}
.PlayerScreenPlayCaptain-correct {
  background: var(--correct-color);
}

.PlayerScreenPlayCaptain-wrong {
  background: var(--wrong-color);
}
.PlayerScreenPlayCaptain-nevermind {
  transition: all 0.25s ease;
  box-sizing: border-box;
}
